import NavPage from "../../Common/NavPage";
import ResponseCard from "../Responses/ResponseCard";

const Responses = () => {
  return (
    <NavPage title="Responses">
      <ResponseCard />
      <ResponseCard />
      <ResponseCard />
      <ResponseCard />
    </NavPage>
  );
};

export default Responses;
