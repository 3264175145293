import { TextField } from "@material-ui/core";
import { useCallback, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "wouter";

import { signupFinish } from "../api";
import { MAX_CHARS_FIRST_NAME, MAX_CHARS_LAST_NAME } from "../constants";
import { AppContext } from "../contexts/appContext";
import bolts from "../images/black-bolts.svg";
import ButtonPill from "./Common/ButtonPill";
import FatTitle from "./Common/FatTitle";
import FixedImg from "./Common/FixedImg";
import YellowScreen from "./Common/YellowScreen";

const SignUpFinish = ({ params }) => {
  const { token } = params;
  const { displayError, displayModal, setToken } = useContext(AppContext);

  const [, setLocation] = useLocation();

  // step 0: explanation
  // step 1: user details
  const [signUpFinishStep, setSignupFinishStep] = useState(0);
  const { handleSubmit, control } = useForm();

  const Explanation = () => (
    <div className="container mx-auto p-4 flex justify-center items-center flex-col">
      <div className="py-8">
        <FatTitle>You're in!</FatTitle>
      </div>

      <div className="flex flex-col space-y-8 items-center sm:w-1/2">
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </div>
        <ButtonPill large={true} onClick={(e) => setSignupFinishStep(1)}>
          Next
        </ButtonPill>
      </div>
    </div>
  );

  /*
  const ExampleTwelve = () => (
    <div className="container mx-auto p-4 flex justify-center items-center flex-col">
      <FixedImg src={logo} alt="12seconds" className="w-48" />
      <div>
        <video controls className="my-8">
          <source
            src="https://cdn.team.12seconds.tv/UG7TN7RW8ZF1/SP8JX2KU6CQ2/e0ce6b83-ed17-4e39-9509-a587a66eb1a2-final.mp4"
            type="video/webm"
          />
        </video>
      </div>
      <div className="mb-8">This is what a end-of-day video looks like.</div>
      <ButtonPill large={true} onClick={(e) => setSignupFinishStep(2)}>
        Next
      </ButtonPill>
    </div>
  );
  */

  const UserDetails = () => (
    <div className="container mx-auto p-4 flex justify-center items-center flex-col">
      <div className="mb-4">
        <div className="py-4">
          <FatTitle>Hey, fill this out real quick...</FatTitle>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-4 w-3/4"
      >
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: "Email required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Email"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />

        <Controller
          name="firstName"
          control={control}
          defaultValue=""
          rules={{ required: "First name required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="First Name"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              inputProps={{ maxLength: MAX_CHARS_FIRST_NAME }}
              helperText={error ? error.message : null}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          defaultValue=""
          rules={{ required: "Last name required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Last Name"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              inputProps={{ maxLength: MAX_CHARS_LAST_NAME }}
              helperText={error ? error.message : null}
            />
          )}
        />

        <div className="text-center py-6 mt-6">
          <ButtonPill type="submit" large={true}>
            Next
          </ButtonPill>
        </div>
      </form>
    </div>
  );

  const onSubmit = useCallback(
    async (values) => {
      try {
        const result = await signupFinish(
          values.firstName,
          values.lastName,
          values.email,
          token
        );
        setToken(token);
        console.log("success", result);
        // we don't have avatars yet
        // setLocation("/signup-photo");
        setLocation("/home");
      } catch (err) {
        // TODO handle AXIOS exceptions!

        //console.log(util.inspect(err, { depth: 10 }));
        // if account already exists, we should just log the person in
        const status = err.response.status;
        if (status === 409) {
          // 409 means the account already exists
          // try to log the user in anyway
          setLocation(`/login/${token}`);
        } else if (status === 401) {
          // Edge case: User may have logged in with this token already and logged out, killing it.
          // TODO check signup/info API first instead!
          displayModal(
            "Whoops!",
            "Sorry, this signup link is too old.",
            () => setLocation("/home"),
            "Okay",
            false
          );
        } else {
          displayError(err.response.data.Error.Message);
        }
      }
    },
    [params.token, displayError]
  );

  return (
    <YellowScreen>
      {(signUpFinishStep === 0 && <Explanation />) ||
        (signUpFinishStep == 1 && <UserDetails />)}
    </YellowScreen>
  );
};
export default SignUpFinish;
