import util from "util";

import { ChevronLeftIcon } from "@heroicons/react/solid";
import { TextField } from "@material-ui/core";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useLocation } from "wouter";

import { loginStart, signupStart, verifyCode } from "../api";
import { AppContext } from "../contexts/appContext";
import bolts from "../images/black-bolts.svg";
import puff from "../images/puff-black.svg";
import logo from "../images/twelve-logo.svg";
import AuthFooter from "./Common/AuthFooter";
import FatTitle from "./Common/FatTitle";
import FixedImg from "./Common/FixedImg";
import FixedLink from "./Common/FixedLink";
import GoButton from "./Common/GoButton";
import SafeAreaInsetTop from "./Common/SafeAreaInsetTop";
import YellowScreen from "./Common/YellowScreen";
import Spinner from "./Spinner";

// for reference, using react-hook-form with material-ui
// https://levelup.gitconnected.com/using-react-hook-form-with-material-ui-components-ba42ace9507a

const Auth = ({ isSignup, code }) => {
  const queryClient = useQueryClient();
  const { me, displayModal } = useContext(AppContext);

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { handleSubmit, control } = useForm();
  const [, setLocation] = useLocation();
  const [phoneNumber, setPhoneNumber] = useState(null); // we need the canonical phone number for the verification code

  // if a user is logged in, and gets sent to this invite
  // page with a code, we just try to join the group
  // and then redirect there
  /*
  useEffect(() => {
    if (me && code) {
      joinGroup(code)
        .then(() => {
          queryClient.invalidateQueries("queryGroups"); // Refresh Groups
          queryClient.invalidateQueries("queryHomePageDedupedData"); // Refresh Prompts
          displayModal("Success!", "You've joined a new team!");
          setLocation("/videos");
        })
        .catch((err) => {
          //console.log(util.inspect(me, { depth: 5 }));
          // Since user is already logged in and has clicked a link, we use displayError
          // and reroute to /videos
          displayModal("Whoops!", err.response.data.Error.Message);
          setLocation("/videos");
        });
    }
  }, [me, code, setLocation, queryClient]);
  */

  const onSubmit = async (values) => {
    setSubmitting(true);
    setErrorMessage(); //clear error

    if (isSignup) {
      const result = await signupStart(values.phone, values.code).catch(
        (err) => {
          console.log(err.response);
          setErrorMessage(
            err.response.data.Error.FieldValidationResults?.Code ||
              err.response.data.Error.Message
          );
          setSubmitting(false);
        }
      );
      if (result) {
        const token = result.data?.Data?.Token;
        console.log("result", result.data?.Data);
        if (token) {
          // Signups via email invites return token and don't require email verification step
          setLocation(`/signup/${token}`);
        } else {
          setPhoneNumber(result.data?.Data?.PhoneNumber);
          // Else go to enter code
          setSubmitted(true);
          setSubmitting(false);
        }
      }
    } else {
      const result = await loginStart(values.phone).catch((err) => {
        setErrorMessage(err.response.data.Error.Message);
        setSubmitting(false);
      });
      if (result) {
        console.log(result);
        setPhoneNumber(result.data?.Data?.PhoneNumber);
        setSubmitted(true);
        setSubmitting(false);
      }
    }
  };

  const onSubmitCode = async (values) => {
    setSubmitting(true);
    setErrorMessage(); //clear error
    const result = await verifyCode(phoneNumber, values.verifyCode).catch(
      (err) => {
        console.log(err.response);
        setErrorMessage(
          err.response.data.Error.FieldValidationResults?.Code ||
            err.response.data.Error.Message
        );
        setSubmitting(false);
      }
    );

    if (result) {
      const token = result.data?.Data?.ApiToken;
      const userId = result?.data?.Data?.UserId;
      console.log("result", result.data?.Data);
      if (token) {
        if (userId) {
          // user has completed signup (name, email, etc)
          setLocation(`/login/${token}`);
        } else {
          // user needs to complete signup
          setLocation(`/signup/${token}`);
        }
      } else {
        // Else go to enter code
        setSubmitted(true);
        setSubmitting(false);
        setErrorMessage("No token found");
      }
    }
  };

  const BackButton = () => (
    <div className="absolute top-5 left-5">
      <SafeAreaInsetTop />
      <FixedLink href="/">
        <div className="flex select-none cursor-pointer">
          <ChevronLeftIcon className="h-6 w-6 text-black" />
          <div className="font-med">Back</div>
        </div>
      </FixedLink>
    </div>
  );

  if (submitting)
    return (
      <div className="flex min-h-screen min-w-screen bg-salmon items-center justify-center">
        <FixedImg src={puff} alt="Loading" />
      </div>
    );

  if (submitted)
    return (
      <YellowScreen>
        <BackButton />
        <div className="p-4">
          <FatTitle>Enter the code we texted you</FatTitle>
        </div>

        <form
          onSubmit={handleSubmit(onSubmitCode)}
          className="flex flex-col space-y-4 w-full px-4"
        >
          <Controller
            name="verifyCode"
            control={control}
            defaultValue=""
            rules={{ required: "Code required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Code"
                variant="filled"
                type="text"
                value={value}
                onChange={(x) => {
                  setErrorMessage(); //Clear error message
                  onChange(x);
                }}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <div className="flex justify-end items-center space-between">
            {errorMessage && (
              <div className="text-red font-bold w-full pr-2">
                {errorMessage}
              </div>
            )}
            <GoButton type="submit" />
          </div>
        </form>
      </YellowScreen>
    );

  if (me) {
    // We are going to get redirected soon, don't flash the regular page
    return <Spinner />;
  }

  return (
    <YellowScreen>
      <BackButton />
      <div>
        <div className="flex flex-col items-center select-none flex-1">
          <div className="mb-8">
            <div className="mt-8 px-4">
              <FatTitle>
                {isSignup
                  ? "Create an account with your invite code"
                  : "Sign in with your phone number"}
              </FatTitle>
            </div>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col space-y-4 w-full px-4"
          >
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{ required: "Phone number required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Phone number"
                  variant="filled"
                  type="text"
                  autoComplete="tel"
                  value={value}
                  onChange={(x) => {
                    setErrorMessage(); //Clear error message
                    onChange(x);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            {isSignup && (
              <Controller
                name="code"
                control={control}
                defaultValue={code || ""}
                rules={{ required: "Invitation code required" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Invitation code"
                    variant="filled"
                    value={value}
                    onChange={(x) => {
                      setErrorMessage(); //Clear error message
                      onChange(x);
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
            <div className="flex justify-end items-center space-between">
              {errorMessage && (
                <div className="text-red font-bold w-full pr-2">
                  {errorMessage}
                </div>
              )}
              <GoButton type="submit" />
            </div>
            <div className="py-4 text-center">
              {isSignup ? (
                <span>
                  Already have an account?{" "}
                  {
                    <FixedLink className="font-bold" href="/login">
                      Sign in
                    </FixedLink>
                  }
                </span>
              ) : (
                <span>
                  Don't have an account yet?{" "}
                  {
                    <FixedLink className="font-bold" href="/signup">
                      Sign up
                    </FixedLink>
                  }
                </span>
              )}
            </div>
          </form>
        </div>
        <AuthFooter />
      </div>
    </YellowScreen>
  );
};

export default Auth;
