import Div100vh from "react-div-100vh";
import { Redirect, Route, Switch } from "wouter";

import BadgeCountProvider from "../providers/BadgeCountProvider";
import NavProvider from "../providers/NavProvider";
import EditQuestion from "./Ask/Questions/EditQuestion";
import Questions from "./Ask/Questions/Questions";
import Responses from "./Ask/Responses/Responses";
import ErrorPage from "./ErrorPage/ErrorPage";
import BottomNavBar from "./Navigation/BottomNavBar";
import TopNavBar from "./Navigation/TopNavBar";
import Settings from "./Settings";

// Exported for App.js router, which will send all these path values here. DRY.
export const PATHS = {
  addQuestion: "/questions/edit",
  questions: "/questions",
  responses: "/responses",
  settings: "/settings",
  home: "/home",
};

const Main = () => {
  const searchParams = window.location.search;
  return (
    <BadgeCountProvider>
      <NavProvider>
        <Div100vh>
          {/* ^ Div100vh is used for magic fix to mobile safari screen height sizing */}
          <div className="flex flex-col h-full">
            <TopNavBar />
            <Switch>
              <Route path={PATHS.responses} component={Responses} />
              <Route path={PATHS.addQuestion} component={EditQuestion} />
              <Route path={PATHS.questions} component={Questions} />
              <Route path={PATHS.settings} component={Settings} />
              <Route path={PATHS.home}>
                <Redirect to={PATHS.responses} />
              </Route>
              <Route>
                <ErrorPage code="404" message="Page not found" />
              </Route>
            </Switch>
            <BottomNavBar />
          </div>
        </Div100vh>
      </NavProvider>
    </BadgeCountProvider>
  );
};

export default Main;
