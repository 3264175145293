import { useState } from "react";

import ButtonPill from "../../Common/ButtonPill";
import NavPage from "../../Common/NavPage";
import SaveControls from "../../Common/SaveControls";

const EditQuestion = () => {
  const [question, setQuestion] = useState("");

  const handleSave = async (event) => {};

  const handleCancelSave = async (event) => {
    setQuestion("");
  };

  return (
    <NavPage
      title="Add Question"
      showBackButton={true}
      backLocation={`/questions`}
    >
      <div className="flex flex-col space-y-8 mb-12">
        <div>
          <textarea
            rows="3"
            className="rounded-lg shadow w-full p-4"
            placeholder="Enter your question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <SaveControls
            show={question !== ""}
            onSave={handleSave}
            onCancel={handleCancelSave}
            saving={false}
            saveEnabled={question !== ""}
          />
        </div>
        <div className="flex flex-col space-y-6">
          <div className="text-gray-500 text-center">
            Type your question, or tap on a topic below for random ideas. Tap
            more than once to change.
          </div>
          <div className="mx-auto grid grid-cols-3 gap-4">
            {[
              "Silly",
              "Family",
              "Work",
              "Sports",
              "News",
              "Sex",
              "Celebs",
              "Money",
              "Music",
              "Movies",
              "Strange",
              "LGBTQ",
              "Memories",
              "Physical",
              "Singles",
            ].map((topic) => (
              <ButtonPill
                key={topic}
                bgColor={"salmon"}
                small={true}
                fullWidth={true}
                onClick={() => setQuestion(`Do you like ${topic}?`)}
              >
                {topic}
              </ButtonPill>
            ))}
          </div>
        </div>
      </div>
    </NavPage>
  );
};

export default EditQuestion;
