import { useState } from "react";
import Div100vh from "react-div-100vh";

import { waitingList } from "../../api";
import FixedLink from "../Common/FixedLink";
import SafeAreaInsetBottom from "../Common/SafeAreaInsetBottom";
import SafeAreaInsetTop from "../Common/SafeAreaInsetTop";
import MarketingFooter from "./MarketingFooter";

const WaitingListSignup = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    if (email === "") {
      return;
    }
    const result = await waitingList(email).catch((error) => {
      console.log(error);
      setErrorMessage("Please enter a valid email address.");
      return;
    });

    if (result) {
      setSubmitted(true);
    }
  };

  if (submitted) {
    return <div className="p-4 text-center">Thanks! We'll be in touch!</div>;
  }

  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="flex flex-col space-y-2 px-4 pb-4 w-full">
        <div>
          <input
            placeholder="Email address"
            className="rounded w-full p-4 text-black"
            type="email"
            value={email}
            onChange={onChange}
          />
        </div>
        <div>
          <button
            className="rounded w-full bg-black text-white py-4 uppercase font-bold text-xl cursor-pointer select-none"
            type="submit"
          >
            Submit
          </button>
        </div>
        {errorMessage && <div className="text-sm text-red">{errorMessage}</div>}
      </div>
    </form>
  );
};

const MarketingHome = () => {
  return (
    <Div100vh>
      <div className="flex flex-col h-full bg-black">
        <div className="flex-1 flex flex-col w-full overflow-y-scroll">
          <SafeAreaInsetTop />
          <div className="flex flex-col flex-1 bg-salmon pb-16">
            <div className="font-bold text-right px-8 py-6">
              <FixedLink href="/signin">Sign In</FixedLink>
            </div>
            <div className="flex flex-1 flex-col container mx-auto items-center justify-center">
              <div className="uppercase font-headline text-5xl sm:text-6xl text-center">
                🤫
              </div>
              <div className="flex flex-col w-full sm:w-96">
                <div className="px-4 py-4 text-lg font-light text-center">
                  <span className="font-bold">Request pre-launch access</span>
                </div>
                <WaitingListSignup />
              </div>
            </div>
          </div>
          <MarketingFooter />
          <SafeAreaInsetBottom />
        </div>
      </div>
    </Div100vh>
  );
};

export default MarketingHome;
