import { createContext } from "react";

export const NavContext = createContext(null);

export const BottomNavEnum = {
  HIDDEN: 0,
  RESPONSES: 1,
  QUESTIONS: 2,
  SETTINGS: 3,
};
