import axios from "axios";

function getEndpoint(path) {
  return process.env.REACT_APP_API_ROOT_URL + path;
}

function callApi(path, method = "get", data = {}, token) {
  //console.log("API: ", path);
  if (!token) {
    token = localStorage.getItem("token");
  }
  const url = getEndpoint(path);
  return axios({
    method,
    url,
    headers: {
      "x-team12api-token": token,
    },
    data,
  });
}

// Calling this clears push device tokens
export function authLogout(token) {
  return callApi("/auth/logout", "post", undefined, token);
}

export function loginStart(phone) {
  return callApi("/signup/start", "post", { PhoneNumber: phone });
}

export function signupStart(phone, code) {
  return callApi("/signup/startWithInviteCode", "post", {
    PhoneNumber: phone,
    InviteCode: code,
  });
}

export function verifyCode(phone, code) {
  return callApi("/signup/verify", "post", {
    PhoneNumber: phone,
    Code: code,
  });
}

export function signupFinish(firstName, lastName, email, token) {
  return callApi(
    "/signup/finish",
    "post",
    {
      FirstName: firstName.trim(),
      LastName: lastName.trim(),
      Email: email.trim(),
      TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    token
  );
}

export function getCurrentPromptForGroup(groupId) {
  return callApi(`/prompt/${groupId}/current`);
}

export function getCurrentPromptsForAllGroups() {
  return callApi(`/prompt/current`);
}

export function getPromptHistoryForGroup(groupId) {
  return callApi(`/prompt/${groupId}/history`);
}

export function getPrompt(promptId) {
  return callApi(`/prompt/${promptId}`);
}

export function setPromptAsWatched(promptId) {
  return callApi(`/prompt/${promptId}/watched`, "post");
}

export function getVideoUploadUrl(promptId) {
  return callApi(`/prompt/${promptId}/upload`);
}

export function uploadVideo(url, data, setProgressPercent) {
  return axios({
    method: "put",
    url,
    data,
    onUploadProgress: (progressEvent) => {
      const totalLength = progressEvent.lengthComputable
        ? progressEvent.total
        : progressEvent.target.getResponseHeader("content-length") ||
          progressEvent.target.getResponseHeader(
            "x-decompressed-content-length"
          );
      if (totalLength !== null) {
        setProgressPercent(
          Math.round((progressEvent.loaded * 100) / totalLength)
        );
      }
    },
  });
}

export function getProfilePicUploadUrl() {
  return callApi("/users/photoUploadUrl");
}

export function uploadProfilePic(url, data) {
  return axios({
    method: "put",
    headers: {
      "Content-Type": "image/jpeg",
    },
    url,
    data,
  });
}

export function submitResponse(promptId, videoId) {
  return callApi(`/prompt/${promptId}/submit`, "post", { VideoId: videoId });
}

export function getMe() {
  return callApi("/users/me");
}

export function updateMe(data) {
  return callApi("/users/me", "post", data);
}

export function getMyPromptResponses(groupId) {
  return callApi(`/prompt/${groupId}/responses/me`);
}

export function getRecentPrompts() {
  return callApi("/users/recentPrompts");
}

export function getUsers(users) {
  return callApi(`/users/?ids=${users.join(",")}`);
}

export function postComment(promptId, commentText) {
  return callApi(`/prompt/${promptId}/comment`, "post", {
    CommentText: commentText.trim(),
  });
}

export function deleteComment(promptId, commentId) {
  return callApi(`/prompt/${promptId}/comment/${commentId}`, "delete");
}

export function getGroups() {
  return callApi("/groups/mine");
}

export function getGroup(groupId) {
  return callApi(`/groups/${groupId}`);
}

export function createGroup(name, emoji) {
  return callApi("/groups/create", "post", {
    Name: name.trim(),
    Emojicon: emoji,
    TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ScheduleStyle: "AlwaysOpen",
  });
}

export function deleteGroup(groupId) {
  return callApi(`/groups/${groupId}`, "delete");
}

export function joinGroup(inviteCode) {
  return callApi(`/groups/join/${inviteCode}`, "post");
}

export function updateGroup(groupId, data) {
  return callApi(`/groups/${groupId}`, "post", data);
}

export function leaveGroup(groupId) {
  return callApi(`/groups/${groupId}/leave`, "post");
}

export function sendInvites(groupId, data) {
  return callApi(`/groups/${groupId}/invite`, "post", data);
}

export function resendInvite(groupId, data) {
  return callApi(`/groups/${groupId}/invite/resend`, "post", data);
}

export function deleteInvite(groupId, data) {
  return callApi(`/groups/${groupId}/invite`, "delete", data);
}

export function removeGroupMember(groupId, memberId) {
  return callApi(`/groups/${groupId}/remove/${memberId}`, "post");
}

export function getGroupManage(groupId) {
  return callApi(`/groups/${groupId}/manage`);
}

export function refreshPrompt(groupId, timestamp) {
  return callApi(`/groups/${groupId}/manage/refresh/${timestamp}`, "post");
}

export function setPromptForDate(groupId, timestamp, promptText) {
  return callApi(`/groups/${groupId}/manage/${timestamp}`, "post", {
    PromptText: promptText,
  });
}

export function getNotifications() {
  return callApi(`/users/notifications`);
}

export function setNotificationsRead() {
  return callApi("/users/notifications/read", "post");
}

export function registerDevice(data) {
  return callApi(`/users/device`, "post", data);
}

export function waitingList(email) {
  return callApi("/signup/list", "post", {
    EMail: email,
  });
}

export const getAvailableVersion = async () => {
  const config = {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  };
  let version;
  try {
    const response = await axios.get("/version.txt", config);
    if (response.status === 200) {
      // Don't treat 404 or other pages as versions!
      version = response.data;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return version;
};
