import { DotsHorizontalIcon } from "@heroicons/react/solid";

import FixedLink from "../../Common/FixedLink";

const QuestionCard = ({
  questionNumber = 1,
  question = "What's your favorite color?",
}) => {
  return (
    <FixedLink to="/questions/edit">
      <div className="flex mb-6 border-b items-center bg-white shadow rounded cursor-pointer">
        <div className="w-16 h-16 rounded-full bg-salmon text-white font-bold flex items-center justify-center ml-4">
          <div>{questionNumber}</div>
        </div>
        <div className="flex-1 flex flex-col justify-between p-4">
          <div className="py-6">{question}</div>
        </div>
        <div className="mr-4">
          <DotsHorizontalIcon className="text-gray-500 w-6" />
        </div>
      </div>
    </FixedLink>
  );
};

export default QuestionCard;
