import { DeviceMobileIcon } from "@heroicons/react/solid";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import useFitText from "use-fit-text";

import { NavContext } from "../../contexts/navContext";
import FixedLink from "../Common/FixedLink";
import SafeAreaInsetTop from "../Common/SafeAreaInsetTop";
import GetTheApp from "../GetTheApp";

const TopNavBar = () => {
  const {
    pageTitle,
    showBackButton,
    backButtonText,
    backLocation,
    allowShowGetTheApp,
  } = useContext(NavContext);

  const heightClass = "h-12 sm:h-16";

  const [hiddenClass, setHiddenClass] = useState("opacity-0");

  const { fontSize, ref: fitTextRef } = useFitText({
    minFontSize: 10, // min size in %
    maxFontSize: 150, // max size in %
    logLevel: "debug",
    onFinish: () => {
      // Have 0 opacity until size is calculated to eliminate flashing
      setHiddenClass("opacity-100");
    },
  });

  useEffect(() => {
    setHiddenClass("opacity-0");
  }, [pageTitle]);

  return (
    <div
      className={`w-full flex flex-col justify-center bg-salmon text-white z-10 shadow-sm`}
    >
      {/* ^ z-10 required to get shadow to show */}
      <SafeAreaInsetTop bgColor="yellow" />
      <div
        className={`container grid grid-cols-5 mx-auto items-center px-3 ${heightClass}`}
      >
        <div className="grid-cols-1">
          {showBackButton && !!backLocation && (
            <FixedLink href={backLocation}>
              <div className="flex select-none cursor-pointer -ml-2">
                <ChevronLeftIcon className="h-6 w-6 text-white" />
                <div className="font-med -ml-0.5">{backButtonText}</div>
              </div>
            </FixedLink>
          )}
        </div>
        <div
          ref={fitTextRef}
          style={{ fontSize }}
          className={`col-start-2 col-end-5 font-bold w-full h-2/3 flex items-center justify-center px-3 overflow-hidden ${hiddenClass}`}
        >
          {pageTitle}
        </div>
        <div className="grid-cols-5">
          {allowShowGetTheApp && false && (
            <div className="flex items-center justify-end">
              <GetTheApp />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
