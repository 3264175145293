import ReactPlayer from "react-player";

import ButtonPill from "../../Common/ButtonPill";
import NavPage from "../../Common/NavPage";
import QuestionCard from "./QuestionCard";

const Questions = () => {
  return (
    <NavPage title="Questions">
      <div className="flex flex-col space-y-12 mb-12">
        <div className="flex flex-col space-y-4">
          <div className="text-center text-lg">Your magic link!</div>
          <input
            className="mx-8 p-4 rounded-full shadow"
            readOnly
            value="http://deez.nuts"
          />
          <div className="flex justify-center space-x-6">
            <ButtonPill>Copy</ButtonPill>
            <ButtonPill>Share</ButtonPill>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-between">
            <div>Your Intro Video</div>
            <div>
              <ButtonPill small={true} bgColor={"salmon"}>
                Record new
              </ButtonPill>
            </div>
          </div>
          <div>
            <div className="w-48 mx-auto rounded-3xl relative">
              <ReactPlayer
                url={process.env.PUBLIC_URL + "/video/12sample.mp4"}
                playing={true}
                loop={true}
                width={"100%"}
                height={"100%"}
                playsinline={true}
                muted={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between items-center">
            <div>Questions (tap a question to change it)</div>
          </div>
          <QuestionCard
            questionNumber={1}
            question="What's your favorite color?"
          />
          <QuestionCard
            questionNumber={2}
            question="Have you ever had a funny celebrity encounter?"
          />
          <QuestionCard
            questionNumber={3}
            question="What motivates you in life?"
          />
        </div>
      </div>
    </NavPage>
  );
};

export default Questions;
