const ResponseCard = () => {
  return (
    <div className="flex space-x-4 pb-6 mb-6 border-b">
      <div
        className="min-h-full w-32"
        style={{
          backgroundColor: "#CCC", // Be gray while image is loading
          backgroundImage: `url(https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Theodore_Kaczynski.jpg/440px-Theodore_Kaczynski.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      ></div>
      <div className="flex-1 flex flex-col justify-between py-2">
        <div className="text-xs text-gray-600">April 1, 2022</div>
        <div className="py-6">
          <div>
            From <strong>Bobby</strong>:
          </div>
          <div>Responses to Jen's questions</div>
        </div>
        <div className="text-sm">1 response</div>
      </div>
    </div>
  );
};

export default ResponseCard;
