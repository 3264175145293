import { useContext } from "react";

import { ParentAppContext } from "../../contexts/parentAppContext";
import SafeAreaInsetBottom from "./SafeAreaInsetBottom";

const AuthFooter = () => {
  const { hasParentApp } = useContext(ParentAppContext);
  return (
    <div className="absolute bottom-0 left-0 right-0 mx-auto pb-4 text-xs text-center select-none">
      &copy; 2022 Serendipity Systems, Inc.{" "}
      <a
        className="font-bold cursor-pointer select-none"
        href="https://12seconds.freshdesk.com/"
        target="_blank"
        rel="nofollow noreferrer"
      >
        Support
      </a>
      <SafeAreaInsetBottom />
    </div>
  );
};

export default AuthFooter;
