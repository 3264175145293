import { useEffect, useState } from "react";
import { useLocation } from "wouter";

import { BottomNavEnum, NavContext } from "../contexts/navContext";

const TEAMS_PATH = "/teams";
const VIDEOS_PATH = "/videos";
const PROMPTS_PATH = "/prompts";
const NOTIFICATIONS_PATH = "/activity";
const SETTINGS_PATH = "/settings";
const RESPONSES_PATH = "/responses";
const QUESTIONS_PATH = "/questions";

const navEnumForLocation = (location) => {
  const root = "/" + location.split("/")[1];
  let navEnum;
  switch (root) {
    case TEAMS_PATH:
      navEnum = BottomNavEnum.TEAMS;
      break;
    case VIDEOS_PATH:
      navEnum = BottomNavEnum.VIDEOS;
      break;
    case PROMPTS_PATH:
      navEnum = BottomNavEnum.PROMPTS;
      break;
    case NOTIFICATIONS_PATH:
      navEnum = BottomNavEnum.NOTIFICATIONS;
      break;
    case RESPONSES_PATH:
      navEnum = BottomNavEnum.RESPONSES;
      break;
    case QUESTIONS_PATH:
      navEnum = BottomNavEnum.QUESTIONS;
      break;
    case SETTINGS_PATH:
      navEnum = BottomNavEnum.SETTINGS;
      break;
    default:
      navEnum = null;
  }
  return navEnum;
};

const NavProvider = ({ children }) => {
  const [location, setLocation] = useLocation();
  const [bottomNavSelection, setBottomNavSelection] = useState(
    navEnumForLocation(location) // Though this won't work for e.g. /home redirect, with useEffect [location] we're okay.
  );
  const [pageTitle, setPageTitle] = useState("");
  const [showBackButton, setShowBackButton] = useState(false);
  const [backButtonText, setBackButtonText] = useState("Back");
  const [backLocation, setBackLocation] = useState("/");
  const [allowShowGetTheApp, setAllowShowGetTheApp] = useState(false);

  useEffect(() => {
    setBottomNavSelection(navEnumForLocation(location));
  }, [location]);

  const navigateTo = (enumValue) => {
    switch (enumValue) {
      case BottomNavEnum.TEAMS:
        setLocation(TEAMS_PATH);
        break;
      case BottomNavEnum.VIDEOS:
        setLocation(VIDEOS_PATH);
        break;
      case BottomNavEnum.PROMPTS:
        setLocation(PROMPTS_PATH);
        break;
      case BottomNavEnum.NOTIFICATIONS:
        setLocation(NOTIFICATIONS_PATH);
        break;
      case BottomNavEnum.RESPONSES:
        setLocation(RESPONSES_PATH);
        break;
      case BottomNavEnum.QUESTIONS:
        setLocation(QUESTIONS_PATH);
        break;
      case BottomNavEnum.SETTINGS:
        setLocation(SETTINGS_PATH);
        break;
    }
  };

  const updateTopNav = (
    title = "12seconds",
    showBackButton = false,
    backLocation = "/",
    backButtonText = "Back",
    allowShowGetTheApp = false
  ) => {
    setPageTitle(title);
    setShowBackButton(showBackButton);
    setBackLocation(backLocation);
    setBackButtonText(backButtonText);
    setAllowShowGetTheApp(allowShowGetTheApp);
  };

  return (
    <NavContext.Provider
      value={{
        bottomNavSelection,
        navigateTo,
        pageTitle,
        showBackButton,
        backButtonText,
        backLocation,
        updateTopNav,
        allowShowGetTheApp,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export default NavProvider;
